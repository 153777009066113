<template>
  <Navbar :user="user"/>
  <section class="section pt-100 ">
    <div class="container mt-lg-3">
      <div class="row justify-content-center">
        <div class="col-lg-10 col-12">
          <div class="card border-0 rounded shadow">
            <div class="card-body text-start">
              <h5 class="text-center">Personal Information</h5>
              <form @submit.prevent="editPersonal">
                <div class="row mt-4">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">First Name</label>
                      <div class="form-icon position-relative">
                        <input type="text" class="form-control" v-model="user.fname"  >
                        <div v-if="errors.fname" class="text-small text-danger">{{ errors.fname[0] }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Second Name</label>
                      <div class="form-icon position-relative">
                        <input type="text" class="form-control" v-model="user.sname" >
                        <div v-if="errors.sname" class="text-small text-danger">{{ errors.sname[0] }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Last Name</label>
                      <div class="form-icon position-relative">
                        <input type="text" class="form-control" v-model="user.lname" >
                        <div v-if="errors.lname" class="text-small text-danger">{{ errors.lname[0] }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Email</label>
                      <div class="form-icon position-relative">
                        <input type="email" class="form-control" v-model="user.email" >
                        <div v-if="errors.email" class="text-small text-danger">{{ errors.email[0] }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <button type="submit" class="btn default-btn" disabled v-if="submitting">
                      <span class="label">{{ value }}</span>
                    </button>
                    <button type="submit" id="submit" class="btn default-btn" v-else>
                      Save Changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import axios from 'axios'
export default {
  components: { Navbar },
  data(){
    return {
      currentUser: {},
      token: localStorage.getItem('user_data'),
      isLoading: true,
      submitting: false,
      user: {
        email: '', fname: '', sname: '', lname: '', 
      },
      success: false,
      errors: false,
    }
  },
  methods: {
    editPersonal(){
      this.submitting = true
      this.value = 'Please Wait...'
      axios.post('https://apitraining.vipawaworks.com/api/auth/user/user/update',this.user).then(response =>{
        response.data
        this.$router.push({ name: 'TrainerProfile'})
      }).catch((errors) => {
          this.errors = errors.response.data.errors
          this.submitting = false
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login'})
        }
      })
    },
  },
  created(){
    document.title = `Change Password - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser()

  }
};
</script>

<style>
</style>